import { Dependencies } from 'constitute';
import Container from '@/Framework/DI/Container';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import DataroomRpcDispatcher from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import DrsRpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import CondorRpcDispatcher from '@/condor/application/DI/Rpc/HttpDispatcher';
import Rpc, { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';

@Dependencies(Container)
class SessionRepository {
  private dataroomHttpDispatcher: (tenant: string) => Rpc;

  private drsRpcDispatcher: Rpc;

  private condorRpcDispatcher: Rpc;

  constructor(container) {
    container.schedulePostConstructor(
      (drsRpcDispatcher, dataroomHttpDispatcher, condorRpcDispatcher) => {
        this.dataroomHttpDispatcher = dataroomHttpDispatcher;
        this.drsRpcDispatcher = drsRpcDispatcher;
        this.condorRpcDispatcher = condorRpcDispatcher;
      }, [DrsRpcDispatcher, DataroomRpcDispatcher, CondorRpcDispatcher]);
  }

  /**
   * Create session investor DRS
   *
   * @param {Object} data
   * @return {Promise.<*>}
   */
  createInvestorSessionDrs = async (data) => {
    let req = new Request('investor.auth', data);
    let response = await this.drsRpcDispatcher.call<RpcSuccess>(req);

    return response.getResult().payload;
  };

  /**
   * Create session investor Evercall
   *
   * @param {Object} data
   * @param {String} token
   * @return {Promise.<*>}
   */
  createInvestorSessionEvercall = async (data, token) => {
    let req = new Request('evercall.dashboard.auth', data, { token });
    let response = await this.drsRpcDispatcher.call<RpcSuccess>(req);

    return response.getResult().payload;
  };

  /**
   * Create session investor Evercall OACC Registration
   *
   * @param {Object} data
   * @return {Promise.<*>}
   */
  createInvestorSessionEvercallOacc = async (data) => {
    let req = new Request('evercall.oacc.auth', data);
    let response = await this.drsRpcDispatcher.call<RpcSuccess>(req);

    return response.getResult().payload;
  };

  /**
   * Create session for dealmanager
   *
   * @param {Object} data
   * @return {Promise.<*>}
   */
  async createDrsSession(data) {
    let req = new Request('auth', data);
    let response = await this.drsRpcDispatcher.call<RpcSuccess>(req);

    return response.getResult().payload.ssid;
  }

  /**
   * Create session for dataroom
   *
   * @param {Object} data
   * @param tenant
   *
   * @return {Promise.<*>}
   */
  async createDataroomSession(data, tenant: DataroomTenant) {
    let req = new Request('user.authenticate', data);
    let response = await this.dataroomHttpDispatcher(tenant).call<RpcSuccess>(req);

    // @ts-ignore
    return response.getResult().ssid;
  }

  /**
   * Verify that current session is valid for DealVDR
   *
   * @return {Promise<*>}
   */
  async verifyDataroomSession(tenant: DataroomTenant) {
    let req = new Request('user.verify_session');
    let response = await this.dataroomHttpDispatcher(tenant).call<RpcSuccess>(req);

    return response.getResult();
  }

  /**
   * Verify that current session is valid for DealVDR
   *
   * @return {Promise<*>}
   */
  async verifyDealroadshowSession() {
    let req = new Request('roadshow.verify_session');
    let response = await this.drsRpcDispatcher.call<RpcSuccess>(req);

    return response.getResult();
  }

  /**
   * Create session for drs admin
   *
   * @param {Object} data
   * @return {Promise.<*>}
   */
  async createAdminSession(data) {
    let req = new Request('auth', data);
    let response = await this.condorRpcDispatcher.call<RpcSuccess>(req);

    return response.getResult().payload;
  }
}

export default SessionRepository;
