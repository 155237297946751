import React, { createContext, useContext, useState } from 'react';
import { RpcError } from '@dealroadshow/json-rpc-dispatcher';
import noop from 'lodash/noop';
import ErrorCodeHelper from '@finsight/error-codes';
import getRouter from '@/users/infrastructure/next/Router';

import { useSessionContext } from '@/users/application/Session/SessionContext';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { messageCodes } from '@/Framework/Message/messages';

import UsersSessionRepository, {
  INVESTOR_LOGIN_SSID_KEY,
} from '@/users/infrastructure/repository/SessionRepository';
import SessionRepository from '@/users/infrastructure/repository/session/SessionRepository';

import SessionStorageRepository from '@/Framework/browser/storage/SessionStorageRepository';
import { isDataroom } from '@/dataroom/domain/config';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import User from '@/users/domain/User';
import config from '@/Framework/config';
import { getEvercallDashboardLoginCallbackUrl } from '@/evercall/application/dashboard/helpers';

import condorUrl from '@/condor/infrastructure/condorUrl';
import CallbackUrl from '@/Framework/url/CallbackUrl';

import usersUrl from '@/users/infrastructure/usersUrl';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import dmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';
import TenantConfig from '@/Framework/Tenant/TenantConfig';
import UserPermission from '@/users/domain/UserPermission';
import { useDIContext } from '@/Framework/DI/DIContext';

interface IProps {
  children: React.ReactNode,
}

export const LoginContext = createContext(null);

export function useLoginContext() {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error('useLoginContext must be used within the LoginContext');
  }
  return context;
}

function LoginContextProvider({ children }: IProps) {
  const { container } = useDIContext();
  const usersSessionRepository: UsersSessionRepository = container.get(UsersSessionRepository);
  const sessionRepository = container.get<SessionRepository>(SessionRepository);
  let sessionStorage = container.get<SessionStorageRepository>(SessionStorageRepository);
  const { push } = getRouter();
  const {
    propagate, setIsTwoFactorAuthenticationRequired, session, currentUser,
    getCurrentUser, getSession, setSsidCookie,
  } = useSessionContext();

  const [loggingIn, setLoggingIn] = useState(false);
  const [transferring, setTransferring] = useState(false);

  /**
   * Login user and get ssid
   *
   * @param {{
   *   email: String,
   *   password: String
   * }} formData
   */
  async function login(formData) {
    const { email, password } = formData;
    const callbackUrl = CallbackUrl.fromGetParam() || dmPortalUrl.getUrl();
    const tenant = (new URLSearchParams(window.location.search).get('tenant') || config.tenant.dmPortal.code);
    setLoggingIn(true);
    try {
      let ssid;
      let payload = { email, password, tenant };
      switch (true) {
        case config.tenant.dealroadshow.code === tenant:
          ssid = await sessionRepository.createDrsSession(payload);
          break;
        case isDataroom(tenant):
          ssid = await sessionRepository.createDataroomSession(payload, tenant as DataroomTenant);
          break;
        default:
          ssid = await usersSessionRepository.createUserSession(payload);
          break;
      }

      await setSsidCookie(ssid);

      setLoggingIn(false);
      onLoginSuccess();
      await afterLogin({ ssid, callbackUrl });
      return true;
    } catch (e) {
      setLoggingIn(false);
      return onLoginError(e, {
        email,
        callbackUrl: callbackUrl || window.location.href,
        tenant: tenant || TenantConfig.fromHostname().code,
      });
    }
  }

  /**
   * @param {Object} formData
   * @param {Function} createSessionMethod
   * @param {Function} onSuccess
   * @param {Function} onError
   * @return {Promise<boolean|void|*>}
   */
  async function loginInvestor(
    {
      formData,
      createSessionMethod,
      onSuccess,
      onError = noop,
    },
  ) {
    setLoggingIn(true);
    try {
      let { ssid } = await createSessionMethod(formData);
      let user = await usersSessionRepository.getCurrentUser(ssid);
      setLoggingIn(false);

      if (onSuccess) {
        onSuccess({ user, ssid });
      }
      return true;
    } catch (e) {
      setLoggingIn(false);
      if (onError !== noop) {
        return onError(e, formData, onLoginError);
      }
      return onLoginError(e, formData);
    }
  }

  /**
   * Login DRS investor
   *
   * @param {Object} formData
   */
  function loginInvestorDrs(formData) {
    let { email, entryCode, verificationToken } = formData;
    entryCode = entryCode.trim();
    email = email.toLowerCase();

    return loginInvestor(
      {
        formData: { entryCode, email, verificationToken },
        createSessionMethod: sessionRepository.createInvestorSessionDrs,
        onSuccess: async ({ ssid, user }) => {
          if (User.isInvestorProfileFull(user)) {
            await setSsidCookie(ssid);
            window.location.href = `/e/${ entryCode }`;
            return window.location.href;
          }

          sessionStorage.clear();
          // Should go after on login success, since it clears session storage
          sessionStorage.setItem(INVESTOR_LOGIN_SSID_KEY, ssid);
          push(`/login/investor/complete_profile/e/${ entryCode }`);
          return true;
        },
      },
    );
  }

  /**
   * Login Evercall investor
   *
   * @param {Object} formData
   * @param {Function} showCompleteProfileForm
   * @param {String} token
   */
  function loginInvestorEvercall({ formData, showCompleteProfileForm, token }) {
    const { email, dashboardId } = formData;
    const corporateEmail = email.toLowerCase();

    const dashboardIdTrimmed = dashboardId.trim();

    return loginInvestor(
      {
        formData: { dashboardId: dashboardIdTrimmed, corporateEmail },
        createSessionMethod: (payload) => sessionRepository.createInvestorSessionEvercall(
          payload,
          token,
        ),
        onSuccess: ({ ssid, user }) => {
          if (User.isInvestorProfileFull(user)) {
            return afterLogin(
              {
                ssid,
                callbackUrl: getEvercallDashboardLoginCallbackUrl(dashboardIdTrimmed),
              },
            );
          }

          sessionStorage.clear();
          // Should go after on login success, since it clears session storage
          sessionStorage.setItem(INVESTOR_LOGIN_SSID_KEY, ssid);
          showCompleteProfileForm({ user });
          return true;
        },
        onError: (e: RpcError, formData, defaultOnLoginError) => {
          if (
            e.error.code === ErrorCodeHelper.getCodeByName('EVERCALL_CHAT_INVALID_ARGUMENTS')
            && e.error?.data?.exception === 'SubscriptionDeniedException'
          ) {
            NotificationManager.info(getMessage(
              messageCodes.EVERCALL_LOGIN_DENIED_DUE_TO_MULTIPLE_OPEN_TABS,
              { corporateEmail: email, dashboardId }),
            );
            throw e;
          } else {
            return defaultOnLoginError(e, formData);
          }
        },
      },
    );
  }

  /**
   * Login Evercall OACC investor
   *
   * @param {Object} formData
   * @param {Function} showCompleteProfileForm
   * @param {Function} onLoginSucceed
   */
  function loginInvestorEvercallOacc(
    {
      formData,
      showCompleteProfileForm,
      onLoginSucceed,
    },
  ) {
    const { email, conferenceId } = formData;
    const corporateEmail = email.toLowerCase();

    return loginInvestor(
      {
        formData: { corporateEmail, conferenceId },
        createSessionMethod: sessionRepository.createInvestorSessionEvercallOacc,
        onSuccess: async ({ ssid }) => {
          const user = await usersSessionRepository.getCurrentUser(ssid);
          if (User.isInvestorProfileFull(user)) {
            return onLoginSucceed({ ssid });
          }

          sessionStorage.clear();
          // Should go after on login success, since it clears session storage
          sessionStorage.setItem(INVESTOR_LOGIN_SSID_KEY, ssid);
          showCompleteProfileForm({ user });
          return true;
        },
      },
    );
  }

  /**
   * @param {Object} formData
   */
  async function loginAdmin(formData) {
    const callbackUrl = CallbackUrl.fromGetParam() || condorUrl.getUrl();
    const { email, password } = formData;

    try {
      let { ssid } = await sessionRepository.createAdminSession({ email, password });

      await setSsidCookie(ssid);
      onLoginSuccess();
      await afterLogin({ ssid, callbackUrl });

      return true;
    } catch (err) {
      // TODO: Ask @kuzorov
      return onLoginError(err, {
        email,
        callbackUrl: callbackUrl || window.location.href,
      });
    }
  }

  /**
   * @param {Object} formData
   */
  async function loginSso(formData) {
    const { email } = formData;
    const callbackUrl = CallbackUrl.fromGetParam() || finsightWebUrl.getUrl();
    await usersSessionRepository.invalidateSession().catch(() => {});
    window.location.href = usersUrl.getSsoLoginUrl(email, callbackUrl);
  }

  /**
   * Transfer existing session
   *
   * @return {Promise<boolean|void>}
   */
  async function transferExistingSession() {
    if (UserPermission.isTwoFactorAuthenticationRequired(session, currentUser)) {
      setIsTwoFactorAuthenticationRequired(true);
      return false;
    }

    setTransferring(true);
    const callbackUrl = CallbackUrl.fromGetParam() || dmPortalUrl.getUrl();
    const tenant = (new URLSearchParams(window.location.search).get('tenant') || config.defaultTenant);
    let sessionValid;
    try {
      switch (true) {
        case isDataroom(tenant):
          sessionValid = await sessionRepository.verifyDataroomSession(tenant as DataroomTenant);
          break;
        case config.tenant.dealroadshow.code === tenant:
          sessionValid = await sessionRepository.verifyDealroadshowSession();
          break;
        default:
          sessionValid = await usersSessionRepository.verifySession();
          break;
      }
    } catch (error) {
      if (error?.getCode() === ErrorCodeHelper.getCodeByName('TWO_FACTOR_CHECK_REQUIRED')) {
        setIsTwoFactorAuthenticationRequired(true);
        return false;
      }
      setTransferring(false);
    }

    if (!sessionValid) {
      setTransferring(false);
    }

    return propagate({ callbackUrl });
  }

  /**
   * @param {Object} error
   * @param params
   */
  function onLoginError(error, params = {}) {
    if (
        error?.getCode() !== ErrorCodeHelper.getCodeByName('INVESTOR_VERIFICATION_EMAIL_SENT') &&
        error?.getCode() !== ErrorCodeHelper.getCodeByName('USER_ACTIVATION_EMAIL_SENT')
    ) {
      const errorMessage = getErrorMessage(error, params);
      NotificationManager.error(errorMessage);
      throw error;
    }
    // Investor verification email auto sent
    // Activation email auto sent
    throw error;
  }

  function onLoginSuccess() {
    sessionStorage.clear();
  }

  async function afterLogin({ ssid, callbackUrl }: { ssid?: string, callbackUrl?: string }) {
    const [currentUser, currentSession] = await Promise.all([
      getCurrentUser({ clearCache: true }),
      getSession({ clearCache: true }),
    ]);
    if (UserPermission.isTwoFactorAuthenticationRequired(currentSession, currentUser)) {
      setIsTwoFactorAuthenticationRequired(true);
    } else {
      propagate({ ssid, callbackUrl });
    }
  }

  let contextValue = {
    login,
    loginInvestorDrs,
    loginInvestorEvercall,
    loginInvestorEvercallOacc,
    loginAdmin,
    loginSso,
    transferExistingSession,
    transferring,
    loggingIn,
  };

  return <LoginContext.Provider value={ contextValue }>{ children }</LoginContext.Provider>;
}

export default LoginContextProvider;
